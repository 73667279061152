import React, { useEffect } from "react";
import styles from "./Home.module.css";
import appStyles from "../../App.module.css";
import Hero from "../Hero/Hero";
import GenericButton from "../GenericButton/GenericButton";
import Guarantee from "../Guarantee/Guarantee";
import CarrouselMoonligth from "../CarrouselMoonligth/components/index";
import CarrouselTestimonialML from "../SliderTestimonial/components/index";
import metadata from "../../util/data/moonligh_data.json";
import testimony from "../../util/data/moonligh_testimonial.json";
import SectionTitle from "../SectionTitle/SectionTItle";
import Advantage from "../Advantage/Advantage";
import Kit from "../Kit/Kit";

export const Home = props => {
  let heroSmall, heroMedium, heroBig, waves, waves2;

  if (window.webpSupported) {
    heroSmall = require("../../assets/images/webp/hero-small.webp");
    heroMedium = require("../../assets/images/webp/hero-medium.webp");
    heroBig = require("../../assets/images/webp/hero-big.webp");
    waves = require("../../assets/images/webp/BackGround-Waves.webp");
    waves2 = require("../../assets/images/webp/background-Wave2.webp");
  } else {
    heroSmall = require("../../assets/images/jpg/hero-small.jpg");
    heroMedium = require("../../assets/images/jpg/hero-medium.jpg");
    heroBig = require("../../assets/images/jpg/hero-big.jpg");
    waves = require("../../assets/images/png/BackGround-Waves.png");
    waves2 = require("../../assets/images/png/background-Wave2.png");
  }

  let heroSrc = {
    small: heroSmall,
    medium: heroMedium,
    big: heroBig,
    default: heroBig
  };

  const handleBuyPixel = () => {
    const pixel = document.createElement("img");
    pixel.setAttribute(
      "src",
      "hhttps://www.facebook.com/tr?id=2375615839420747&ev=AddToCart&noscript=1"
    );
    document.getElementById("pixelHome").appendChild(pixel);
    window.dataLayer.push({ event: "moonlight-comprar" });
  };

  useEffect(() => {
    const onScroll = e => {
      if (
        document.getElementById("content").scrollHeight - window.scrollY <
        document.getElementById("content").scrollHeight * 0.15
      ) {
        document.getElementById("fix").classList.add(styles.FixDinamic);
        document.getElementById("fix").classList.remove(styles.Fix);
      } else {
        document.getElementById("fix").classList.add(styles.Fix);
        document.getElementById("fix").classList.remove(styles.FixDinamic);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <div className={styles.Container} id="content">
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt="Pixel home"
          src="https://www.facebook.com/tr?id=2375615839420747&ev=PageView&noscript=1"
        />
      </noscript>
      <div className={styles.Fix} id="fix">
        <GenericButton
          to="/comprar"
          label="COMPRAR AHORA"
          event={() => handleBuyPixel()}
        />
      </div>
      <Hero srcset={heroSrc}>
        <div className={appStyles.CompleteDesc} id="pixelHome">
          <h1 className={appStyles.HeroTitle}>
            Dientes más blancos desde casa
          </h1>
          <h1 className={appStyles.HeroDesc}>
            Recupera el brillo y blancura de tu sonrisa con sólo 10 minutos al
            día.
          </h1>
          <h1 className={appStyles.HeroDescBold}>
            Pídelo hoy por sólo <s>$300.000</s>
          </h1>
          <h1 className={appStyles.HeroSubtitle}>$270.000 COP</h1>
          <div className={appStyles.ButtonContainer}>
            <div className={styles.FixButton} id="fix">
              <GenericButton
                to="/comprar"
                label="COMPRAR AHORA"
                event={() => handleBuyPixel()}
              />
            </div>
          </div>
        </div>
      </Hero>

      <div className={styles.Wrapper}>
        <div className={styles.Carrousel}>
          <SectionTitle
            label="Resultados profesionales con 10 minutos al día"
            description="Tener una sonrisa hermosa ahora es más fácil y rápido que nunca."
          />
          <CarrouselMoonligth info={metadata.data} hasDots={true} />
        </div>
      </div>

      <div className={appStyles.KitContainer}>
        <Kit event={() => handleBuyPixel()} />
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.Ventajas}>
          <Advantage />
        </div>
        <div className={styles.Carrousel}>
          <SectionTitle label="Ellos ya aman su sonrisa" />
          <CarrouselTestimonialML info={testimony.data} hasDots={true} />
        </div>
        <div
          className={`${appStyles.GuaranteeContainer} ${appStyles.MarginContainer}`}
        >
          <Guarantee />
        </div>
      </div>
      <div className={styles.BackgroundWaves} id="waves">
        <img src={waves} alt="Moons" className={styles.ImageBig} />
        <img src={waves2} alt="Moons" className={styles.ImageSmall} />
      </div>
    </div>
  );
};
