import React from "react";
import styles from "./Kit.module.css";
import appStyles from "../../App.module.css";
import imgKitPNG from "../../assets/images/png/kit.png";
import imgKitWebp from "../../assets/images/webp/kit.webp";
import GenericButton from "../GenericButton/GenericButton";

const Kit = props => {
  const img = window.webpSupported ? imgKitWebp : imgKitPNG;

  const Title = () => (
    <div className={styles.SectionTitle}>
      Todo para una sonrisa más blanca, en un solo kit
    </div>
  );

  const Image = () => (
    <div className={styles.ImageContainer}>
      <img src={img} className={styles.Image} alt="Moonlight kit" />
    </div>
  );

  const Content = () => (
    <div className={styles.InfoContainer}>
      <div className={styles.Text}>
        Tu kit de blanqueamiento dental moonlight incluye:
      </div>
      <ul className={styles.Items}>
        <li>3 plumas de blanqueamiento</li>
        <li>1 pluma desensibilizante </li>
        <li>1 lámpara LED aceleradora</li>
        <li>colorímetro</li>
      </ul>
      <h1 className={appStyles.HeroDescBold}>
        Pídelo hoy por sólo <s>$300.000</s>
      </h1>
      <div className={appStyles.HeroSubtitle}>$270.000 COP</div>
      <div className={`${appStyles.ButtonContainer} ${styles.ButtonContainer}`}>
        <GenericButton
          to="/comprar"
          label="COMPRAR AHORA"
          event={() => props.event()}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.MobileContainer}>
        <Title />
        <Image />
        <Content />
      </div>
      <div className={styles.DeskContainer}>
        <Image />
        <div className={styles.TIContainer}>
          <Title />
          <Content />
        </div>
      </div>
    </>
  );
};

export default Kit;
