import React, { Component } from "react";
import styles from "./Hero.module.css";
import appStyles from "../../App.module.css";

class Hero extends Component {
  render() {
    let small = this.props.small ? styles.Small : null;
    let image = this.props.image ? (
      <div className={styles.HeroBox}>
        <img src={this.props.image} alt="Moonlight kit" />
      </div>
    ) : null;
    let srcset = this.props.srcset ? (
      <div className={styles.HeroBox}>
        <picture>
          <source srcSet={this.props.srcset.big} media="(min-width: 875px)" />
          <source
            srcSet={this.props.srcset.medium}
            media="(min-width: 400px)"
          />
          <img src={this.props.srcset.small} alt="Moonlight kit" />
        </picture>
      </div>
    ) : null;
    return (
      <>
        <div className={styles.Hero}>
          <div className={`${styles.HeroImage} ${small}`}>
            {image}
            {srcset}
          </div>
          <div
            className={`${styles.HeroInfo} ${appStyles.CenterContainer} ${small}`}
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default Hero;
