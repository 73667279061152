import React, { Component } from "react";
import styles from "./SectionTitle.module.css";

class SectionTitle extends Component {
  render() {
    return (
      <>
        <div className={styles.SectionTitle}>{this.props.label}</div>
        <div className={styles.DescriptionTitle}>{this.props.description}</div>
      </>
    );
  }
}

export default SectionTitle;
