export const getUTMs = () => {
  const url = new URL(window.location.href);
  const utm_source = url.searchParams.get("utm_source");
  const utm_campaign = url.searchParams.get("utm_campaign");
  const utm_medium = url.searchParams.get("utm_medium");
  const creative = url.searchParams.get("creative");
  const appointment = url.searchParams.get("appointment_source");
  const payment = url.searchParams.get("payment_source");
  saveUtm_info(
    utm_source,
    utm_campaign,
    utm_medium,
    creative,
    payment,
    appointment
  );
};

const saveUtm_info = (
  source,
  campain,
  medium,
  creative,
  payment,
  appointment
) => {
  if (source !== null) {
    window.localStorage.setItem("utm_source", JSON.stringify([source]));
    window.localStorage.setItem("utm_campaign", JSON.stringify([campain]));
    window.localStorage.setItem("utm_medium", JSON.stringify([medium]));
    window.localStorage.setItem("creative", JSON.stringify([creative]));
    window.localStorage.setItem("payment_source", JSON.stringify([payment]));
    window.localStorage.setItem(
      "appointment_source",
      JSON.stringify([appointment])
    );

    return {
      lsSource: [source],
      lsCampaign: [campain],
      lsMedium: [medium],
      lsCreative: [creative],
      lsAppo: [appointment],
      lsPayment: [payment],
      visit: 1
    };
  }

  const lsSource = JSON.parse(window.localStorage.getItem("utm_source"));
  const lsCampaign = JSON.parse(window.localStorage.getItem("utm_campaign"));
  const lsMedium = JSON.parse(window.localStorage.getItem("utm_medium"));
  const lsCreative = JSON.parse(window.localStorage.getItem("creative"));
  const lsAppo = JSON.parse(window.localStorage.getItem("appointment_source"));
  const lsPayment = JSON.parse(window.localStorage.getItem("payment_source"));
  let visit = parseInt(window.localStorage.getItem("visit"));

  if (
    lsSource === null ||
    lsCampaign === null ||
    lsMedium === null ||
    lsCreative === null ||
    lsAppo === null ||
    lsPayment === null ||
    visit >= 15
  ) {
    window.localStorage.setItem("utm_source", JSON.stringify(["organico"]));
    window.localStorage.setItem("utm_campaign", JSON.stringify(["organico"]));
    window.localStorage.setItem("utm_medium", JSON.stringify(["organico"]));
    window.localStorage.setItem("creative", JSON.stringify(["organico"]));
    window.localStorage.setItem("payment_source", JSON.stringify(["organico"]));
    window.localStorage.setItem(
      "appointment_source",
      JSON.stringify(["organico"])
    );
    window.localStorage.setItem("visit", 1);
    return {
      lsSource: ["organico"],
      lsCampaign: ["organico"],
      lsMedium: ["organico"],
      lsCreative: ["organico"],
      lsAppo: ["organico"],
      lsPayment: ["organico"],
      visit: 1
    };
  } else {
    lsSource.push(source === null ? "organico" : source);
    lsCampaign.push(campain === null ? "organico" : campain);
    lsMedium.push(medium === null ? "organico" : medium);
    lsCreative.push(creative === null ? "organico" : creative);
    lsAppo.push(appointment === null ? "organico" : appointment);
    lsPayment.push(payment === null ? "organico" : payment);
    window.localStorage.setItem("utm_source", JSON.stringify(lsSource));
    window.localStorage.setItem("utm_campaign", JSON.stringify(lsCampaign));
    window.localStorage.setItem("utm_medium", JSON.stringify(lsMedium));
    window.localStorage.setItem("creative", JSON.stringify(lsCreative));
    window.localStorage.setItem("payment_source", JSON.stringify(lsPayment));
    window.localStorage.setItem("appointment_source", JSON.stringify(lsAppo));
    window.localStorage.setItem("visit", ++visit);
    return {
      lsSource,
      lsCampaign,
      lsMedium,
      lsCreative,
      lsAppo,
      visit
    };
  }
};
