import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import style from "./CheckoutForm.module.css";
import CardSection from "../CardSection/CardSection";
import { LoaderSection } from "../../LoaderSection/LoaderSection.js";
import Error from "../ErrorForm/Error.js";
import { getCountry } from "../../../util/Utils";
export default class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      name: "",
      email: "",
      calle: "",
      numInt: "",
      alcaldia: "",
      colonia: "",
      errorName: false,
      errorEmail: false,
      errorNumber: false,
      errorCalle: false,
      errorNumExt: false,
      errorNumInt: false,
      errorColonia: false,
      errorAlcaldia: false,
      error: false,
      errorData: null,
      loader: false
    };

    let data = this.props.data;
    if (data) {
      this.state.name = data.name;
      this.state.email = data.email;
      this.state.number = data.number;
      this.state.colonia = data.colonia;
      this.state.alcaldia = data.alcaldia;
      this.state.calle = data.calle;
    }
    this.handleSuccess = this.props.handleSuccess;
    console.log(this.props);
    console.log({ state: this.state });
    this.errorTextName = "";
    this.errorTextEmail = "";
    this.errorTextNumber = "";

    this.productKeyName = this.props.productKeyName;
    this.onChangeInputNumber = this.onChangeInputNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUpdate = async () => {
    if (this.state.error) {
      let obj = {
        number: this.state.number,
        name: this.state.name,
        email: this.state.email,
        calle: this.state.calle,
        alcaldia: this.state.alcaldia,
        colonia: this.state.colonia,
        error: true
      };
      console.log(obj);
      this.props.handleError(obj);
    }
  };

  /**
   * Valida el nombre
   */
  validateName = async name => {
    if (name === null || name === undefined || name === "") {
      this.errorTextName = "El nombre no puede estar vacio!";
      this.setState({ errorName: true });
      return false;
    }
    if (name.split(" ").length <= 1) {
      this.errorTextName = "Necesitamos al menos un apellido";
      this.setState({ errorName: true });
      return false;
    }

    this.setState({ errorName: false });
    return true;
  };

  /**
   * Valida el correo
   */
  validateEmail = async email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email.toString().toLowerCase())) {
      this.errorTextEmail = "Error en el correo, verificalo!";
      this.setState({ errorEmail: true });
      return false;
    }

    this.setState({ errorEmail: false });
    return true;
  };

  /**
   * Valida el número
   */
  validatePhone = async phone => {
    if (phone.toString().length < 10) {
      this.errorTextNumber = "El número debe de tener 10 digitos.";
      this.setState({ errorNumber: true });
      return false;
    }

    this.setState({ errorNumber: false });
    return true;
  };

  validateInputCalle = value => {
    if (value === "") {
      this.setState({ errorCalle: true });
      return false;
    }
    this.setState({ errorCalle: false });
    return true;
  };

  validateInputNumExt = value => {
    if (value === "") {
      this.setState({ errorNumExt: true });
      return false;
    }
    this.setState({ errorNumExt: false });
    return true;
  };

  validateInputColonia = value => {
    if (value === "") {
      this.setState({ errorColonia: true });
      return false;
    }
    this.setState({ errorColonia: false });
    return true;
  };

  validateInputAlcaldia = value => {
    if (value === "") {
      this.setState({ errorAlcaldia: true });
      return false;
    }
    this.setState({ errorAlcaldia: false });
    return true;
  };

  onChangeInputNumber = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ number: e.target.value });
    }
  };
  onChangeInputName = e => {
    this.setState({ name: e.target.value });
  };

  onChangeInputEmail = e => {
    this.setState({ email: e.target.value });
  };

  onChangeInput = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleError = error => {
    if (!error) {
      this.setState({ error: false });
    }
  };

  handleReturn = () => {
    window.location.reload()
  }

  handlePixel = () => {
    const pixel = document.createElement('img')

    const url = 'https://www.facebook.com/tr?id=2375615839420747&ev=AddPaymentInfo&noscript=1'
    pixel.setAttribute('src', url)
    document.getElementById('form').appendChild(pixel)
    window.dataLayer.push({ event: "moonlight-pagar" });
  }

  /**
   * Maneja la funcionalidad al momento de apretar el boto de pago
   */
  handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    window.scrollTo(0,0);
    document.getElementById("form").classList.add(style.DisplayNone);
    document.getElementById("loader").classList.remove(style.DisplayNone);

    const { stripe, elements } = this.props;

    let { name, email, number, colonia, calle, alcaldia } = this.state;

    console.log({ name, email, number, colonia, alcaldia });

    let validName = await this.validateName(name);
    let validEmail = await this.validateEmail(email);
    let validNumber = await this.validatePhone(number);
    let validColonia = await this.validateInputColonia(colonia);
    let validCalle = await this.validateInputCalle(calle);
    let validAlcaldia = await this.validateInputAlcaldia(alcaldia);

    console.log(validAlcaldia);
    if (
      !validName ||
      !validEmail ||
      !validNumber ||
      !validColonia ||
      !validCalle ||
      !validAlcaldia
    ) {
      document.getElementById("form").classList.remove(style.DisplayNone);
      document.getElementById("loader").classList.add(style.DisplayNone);
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let aux = getCountry();
    let country = "";
    if(aux === "México"){
      country = "MX";
    }
    else{
      country = "CO";
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
        email: email,
        phone: number,
        address: {
          country: country,
          city: alcaldia + ' ' + colonia + ' ' + calle
        }
      }
    });
    console.log(result);
    this.handlePixel()
    if (result.error) {
      console.log(result.error);
      this.setState({ errorData: result.error });
    } else {
      this.setState({ loader: true });
    }

    const address = {
      Street: calle,
      Neiborhood: colonia,
      City: alcaldia
    }

    this.stripePaymentMethodHandler(result, this.productKeyName, address);

    /* const utcs = {
      utm_source: JSON.parse(window.localStorage.getItem("utm_source"))[0],
      utm_campaign: JSON.parse(window.localStorage.getItem("utm_campaign"))[0],
      utm_medium: JSON.parse(window.localStorage.getItem("utm_medium"))[0],
      creative: JSON.parse(window.localStorage.getItem("creative"))[0]
    };
    await apiBlackBox.Patient(
      number,
      email,
      name,
      utcs,
      "moonlight-landing1",
      "Colombia"
    ); */
  };

  /**
   * Maneja la funcionalidad de stripe para recibir el intento de pago.
   * hace una llamada a la lambda para las funcionalidades de stripe en el back
   */
  stripePaymentMethodHandler = async (result, productKeyName, address) => {
    if (result.error) {
      this.setState({ paymentSuccess: false, loader: false, error: true });
    } else {
      try {
        let paymentMethod = result.paymentMethod;

        const response = await axios.post(process.env.REACT_APP_STRIPE_URL, {
          paymentId: paymentMethod.id,
          keyName: this.productKeyName,
          ...address
        });
        console.log(response);
        let data = response.data;
        console.log("dwadwdadwadwqdwa");
        if (data.success) {
          /*this.setState({
            paymentSuccess: true,
            error: false,
            loader: false
          });*/
          this.handleSuccess({ success: true, name: this.state.name });
        } else {
          this.setState({ paymentSuccess: false, loader: false, error: true });
        }
      } catch (err) {
        this.setState({ error: true, loader: false });
        console.log(err.message);
        this.setState({ paymentSuccess: false, loader: false, error: true });
      }
    }
  };

  render() {
    let {
      errorName,
      errorNumber,
      errorEmail,
      errorCalle,
      errorColonia,
      errorAlcaldia
    } = this.state;
    let labelErrorName = null;
    let labelErrorEmail = null;
    let labelErrorNumber = null;
    let labelErrorColonia = null;
    let labelErrorCalle = null;
    let labelErrorAlcaldia = null;

    if (errorName) {
      labelErrorName = (
        <label className={style.ErrorMessage}>{this.errorTextName}</label>
      );
    }
    if (errorEmail) {
      labelErrorEmail = (
        <label className={style.ErrorMessage}>{this.errorTextEmail}</label>
      );
    }
    if (errorNumber) {
      labelErrorNumber = (
        <label className={style.ErrorMessage}>{this.errorTextNumber}</label>
      );
    }

    if (errorCalle) {
      labelErrorCalle = (
        <label className={style.ErrorMessage}>Ingresa una Calle</label>
      );
    }

    if (errorColonia) {
      labelErrorColonia = (
        <label className={style.ErrorMessage}>Ingresa un Barrio</label>
      );
    }

    if (errorAlcaldia) {
      labelErrorAlcaldia = (
        <label className={style.ErrorMessage}>Ingresa un Municipio</label>
      );
    }

    if (this.state.error) {
      return <Error handleReturn={this.handleReturn}></Error>;
    }
    return (
      <div className={style.marginForm}>
        <div
          className={`${style.FormContainer} ${style.DisplayNone} ${style.CenterLoader}`}
          id="loader"
        >
          <LoaderSection />
        </div>
        <h1 className={style.Title}>Tus datos</h1>
        <form
          onSubmit={this.handleSubmit}
          className={style.FormContainer}
          id="form"
        >
          <div className={style.Form}>
            <label htmlFor="name" className={style.Inp}>
              <input
                type="text"
                id="name"
                placeholder="&nbsp;"
                onChange={this.onChangeInputName}
                value={this.state.name}
              />
              <span className={style.Label}>Tu nombre y apellido</span>
              <span className={style.Border}></span>
            </label>
            {labelErrorName}
          </div>
          <div className={style.Form}>
            <label htmlFor="number" className={style.Inp}>
              <input
                type="text"
                id="number"
                placeholder="&nbsp;"
                onChange={this.onChangeInputNumber}
                value={this.state.number}
              />
              <span className={style.Label}>Teléfono de contacto</span>
              <span className={style.Border}></span>
            </label>
            {labelErrorNumber}
          </div>
          <div className={style.Form}>
            <label htmlFor="email" className={style.Inp}>
              <input
                type="email"
                id="email"
                placeholder="&nbsp;"
                onChange={this.onChangeInputEmail}
                value={this.state.email}
              />
              <span className={style.Label}>Correo electrónico</span>
              <span className={style.Border}></span>
            </label>
            {labelErrorEmail}
          </div>
          <div>
            <h1 className={style.Title}>Datos de envío</h1>
          </div>
          <div className={style.Form}>
            <label htmlFor="calle" className={style.Inp}>
              <input
                type="text"
                id="calle"
                placeholder="&nbsp;"
                onChange={this.onChangeInput}
                value={this.state.calle}
              />
              <span className={style.Label}>Calle</span>
              <span className={style.Border}></span>
            </label>
            {labelErrorCalle}
          </div>
          <div className={style.Form}>
            <label htmlFor="colonia" className={style.Inp}>
              <input
                type="text"
                id="colonia"
                placeholder="&nbsp;"
                onChange={this.onChangeInput}
                value={this.state.colonia}
              />
              <span className={style.Label}>Barrio</span>
              <span className={style.Border}></span>
            </label>
            {labelErrorColonia}
          </div>
          <div className={style.Form}>
            <label htmlFor="alcaldia" className={style.Inp}>
              <input
                type="text"
                id="alcaldia"
                placeholder="&nbsp;"
                onChange={this.onChangeInput}
                value={this.state.alcaldia}
              />
              <span className={style.Label}>Municipio</span>
              <span className={style.Border}></span>
            </label>
            {labelErrorAlcaldia}
          </div>
          <div className={style.Form}>
            <h1 className={style.cardTitle}>Tarjeta de crédito/débito</h1>
          </div>
          <div id="cardnumber" className={style.Form}>
            <label htmlFor="cardnumber" className={style.Inp}>
              <CardSection />
              <span className={style.Border}></span>
            </label>
          </div>

          <button
            type="submit"
            className={`${style.actionButton} ${style.payButton}`}
          >
            Pagar
          </button>
        </form>
      </div>
    );
  }
}
