import React, { Component } from "react";
import styles from "./Guarantee.module.css";
import seal from "../../assets/images/svg/seal.svg";

class Guarantee extends Component {
  render() {
    return (
      <>
        <div className={styles.Guarantee}>
          <div className={styles.GuaranteeContent}>
            <div className={styles.Seal}>
              <img src={seal} alt="Garantia Moons" />
            </div>
            <div className={styles.GuaranteeText}>
              <div className={styles.GuaranteeTitle}>
                Resultados garantizados
              </div>
              <div className={styles.GuaranteeDesc}>
                <div>
                  Si después de usar moonlight todos los días, durante 10 días
                  no notas tus dientes más blancos, te devolvemos tu dinero.
                </div>
                <div className={styles.GuaranteeLink}>
                  Mira aquí la{" "}
                  <a href="https://mymoons.co/politica-de-devoluciones">
                    Política de Devoluciones.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Guarantee;
