import React from "react";
import styles from "./Footer.module.css";
import imgFacebook from "../../assets/images/svg/facebook.svg";
import imgInstagram from "../../assets/images/svg/instagram.svg";
import imgLinkedIn from "../../assets/images/svg/linked-in.svg";
import imgTwitter from "../../assets/images/svg/twitter.svg";
import imgYoutube from "../../assets/images/svg/youtube.svg";

export const Footer = props => {
  return (
    <div className={styles.Container} id="footer">
      <div className={styles.LinksContainer}>
        <a
          className={styles.Link}
          href="https://mymoons.co/politica-de-devoluciones"
        >
          Garantía
        </a>
        <a
          className={styles.Link}
          href="https://mymoons.co/aviso-de-privacidad"
        >
          Aviso de privacidad
        </a>
        <a
          className={styles.Link}
          href="https://mymoons.co/terminos-y-condiciones"
        >
          Términos del servicio
        </a>
      </div>
      <div className={styles.SocialNetworksContainer}>
        <a href="https://www.linkedin.com/company/alineadores/">
          <img
            className={styles.SocialNetwork}
            src={imgLinkedIn}
            alt="LinkedIn Moons"
          />
        </a>
        <a href="https://twitter.com/welovemoons">
          <img
            className={styles.SocialNetwork}
            src={imgTwitter}
            alt="Twitter Moons"
          />
        </a>
        <a href="https://youtube.com/c/welovemoons">
          <img
            className={styles.SocialNetwork}
            src={imgYoutube}
            alt="Youtube Moons"
          />
        </a>
        <a href="https://www.instagram.com/welovemoons.co/">
          <img
            className={styles.SocialNetwork}
            src={imgInstagram}
            alt="Instagram Moons"
          />
        </a>
        <a href="https://www.facebook.com/welovemoons.co">
          <img
            className={styles.SocialNetwork}
            src={imgFacebook}
            alt="Facebook Moons"
          />
        </a>
      </div>
      <div className={styles.Text}>
        © Moonlight, 2020. Todos los derechos reservados.
      </div>
    </div>
  );
};
