import React from "react";
import styles from "./Header.module.css";
import imgLogo from "../../assets/images/svg/moonlight.svg";

export const Header = props => {
  return (
    <div className={styles.Container}>
      <img className={styles.ImgLogo} src={imgLogo} alt="Moonlight" />
    </div>
  );
};
