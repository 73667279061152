import React from "react";
import style from "./index.module.css";
import Desktop from "./desktop/desktop";
import Mobile from "./mobile/mobile";
import Tablet from "./tablet/tablet";
import styles from "../../Home/Home.module.css";

const CarrouselSteps = ({ info, hasDots, label }) => {
  let backCarrousel;

  if (window.webpSupported) {
    backCarrousel = require("../../../assets/images/webp/Background-1.webp");
  } else {
    backCarrousel = require("../../../assets/images/png/Background-1.png");
  }

  return (
    <div className={style.CarouselContainer}>
      <img
        src={backCarrousel}
        alt="Moons"
        className={styles.RectangleBackground}
      />
      <Mobile info={info} hasDots={hasDots} />
      <Tablet info={info} hasDots={hasDots} />
      <Desktop info={info} hasDots={hasDots} />
    </div>
  );
};

export default CarrouselSteps;
