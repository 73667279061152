import React from 'react';
import styles from './Success.module.css';
import imageSuccessDesktop from '../../../assets/images/png/success_desktop.png';
import imageSuccessMobile from '../../../assets/images/png/success_mobile.png';

export default class Success extends React.Component{


  constructor(props){
    super(props)
    this.name = this.props.name;
  }
  render(){
    window.scrollTo(0,0);
    return (
    <div className={styles.success}>  
      <picture>
        <source media="(max-width: 799px)" srcSet={imageSuccessMobile}></source>
        <img  src={imageSuccessDesktop} alt={"checkout_image"}></img>
      </picture>
      <h1 >¡Gracias por tu compra, {this.name}!</h1>
      <h2 >Recibirás tu pedido en menos de 72 horas. Si tienes dudas, escríbenos a <span style={{color:"#0752f9",fontWeight:"bold"}}>hola@mymoons.co</span></h2>
    </div>)
  }
}