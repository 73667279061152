import React from "react";
import style from "./Checkout.module.css";
import imageDesktop from "../../assets/images/png/checkout_1.png";
import imageMobile from "../../assets/images/png/checkout_mobile.png";

import lock from "../../assets/images/svg/lock.svg";
import shield from "../../assets/images/svg/shield.svg";
import truck from "../../assets/images/svg/truck.svg";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm/CheckoutForm.js";
import Error from './ErrorForm/Error.js';
import Success from "./SuccessForm/Success";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const productKeyName = process.env.REACT_APP_PRODUCT_KEY_NAME;

const stripePromise = loadStripe(stripeKey);

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

function FooterFix(){
  return (
  <div className={style.footerFix}>
  </div>)
}
function CheckoutImage(){
  //return <img className={style.checkoutImage} src={image} alt={"checkout_image"}></img>
  return (
    <picture>
      <source media="(max-width: 799px)" srcSet={imageMobile}></source>
      <img className={style.checkoutImage} src={imageDesktop} alt={"checkout_image"}></img>
    </picture>
  )

}

function ProductInformation() {
  return (
    <div>
      <div className={style.productInformation}>
        <h1 className={style.titleInformation}>
          Kit de blanqueamiento dental moonlight
        </h1>
        <h2 className={style.subtitleInformation}>Contiene:</h2>
        <ul>
          <li>3 plumas de blanqueamiento</li>
          <li>1 pluma desensibilizante</li>
          <li>1 lámpara LED aceleradora</li>
          <li>colorímetro</li>
        </ul>
      </div>
    </div>
  );
}

function FooterIcons() {
  return (
    <div className={`${style.FooterIcons}`}>
      <div className={`${style.iconsMargin}`}>
        <img src={lock} className={style.icon} alt={"lock.svg"}></img>
        {"  "}
        <span> Pago Seguro</span>
      </div>
      <div className={`${style.iconsMargin}`}>
        <img src={truck} className={style.truck} alt={"truck.svg"}></img>
        {"  "}
        <span> Envío gratuito</span>
      </div>
      <div className={`${style.iconsMargin}`}>
        <img src={shield} className={style.icon} alt={"shield.svg"}></img>
        {"  "}
        <span> Resultados garantizados</span>
      </div>
    </div>
  );
}

class Checkout extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      error: false,
      success: false,
      name : ''
    }
    this.data = null;
  }
  handleError = async obj => {
    this.data = obj
    console.log(obj);
    this.setState({error:obj.error});
  }
  handleSuccess = async obj => {
    this.setState({success: obj.success, name: obj.name});
  }

  render() {
    window.scrollTo(0,0);
    if(this.state.error){
      return (
        <div className={style.fatherContainer}>
          <Error data={this.data} handleError={this.handleError}></Error>

        </div>
      )
    }
    if(this.state.success){
      window.dataLayer.push({ event: "moonlight-success" });
      return (
        <div className={style.fatherContainer} id="payment-success">
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            alt="Pixel pago"
            src="https://www.facebook.com/tr?id=2375615839420747&ev=Purchase&noscript=1"
          />
          <Success name={this.state.name}></Success>
        </div>)
    }
    return (
    <div className={style.fatherContainer}>
      <div className={`${style.ContainerFlex} ${style.background} `}>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt="Pixel home"
          src="https://www.facebook.com/tr?id=2375615839420747&ev=PageView&noscript=1"
        />
        <div className={style.col1}>
          <CheckoutImage className={style.img}></CheckoutImage>
          <ProductInformation
            className={style.productInformation}
          ></ProductInformation>
        </div>
        <div className={style.col2}>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <CheckoutForm
                  productKeyName={productKeyName}
                  elements={elements}
                  stripe={stripe}
                  paymentSuccess={this.props.nextStep}
                  handleSuccess={this.handleSuccess}
                  handleError={this.handleError}
                  data={this.data}
                />
              )}
            </ElementsConsumer>
          </Elements>
          <FooterIcons></FooterIcons>
        </div>
      </div>
      <FooterFix></FooterFix>
    </div>
    );
  }
}

export default Checkout;
