import React from 'react';
import styles from './Advantage.module.css';

import plant from '../../assets/images/svg/plant.svg';
import footprint from '../../assets/images/svg/footprint.svg';
import cigar from '../../assets/images/svg/cigar.svg';
import correct from '../../assets/images/svg/correct.svg';



const Advantage = props => {
  return (
    <div className={styles.ContainerAdvantage}>
      <div className={styles.ContentAdvantage}>
        <div className={styles.Items}>
          <img src={plant} alt="" />
          <p className={styles.Description}>vegano</p>
        </div>
        <div className={styles.Items}>
          <img src={footprint} alt="" />
          <p className={styles.Description}>sin probar <br />en animales</p>
        </div>
        <div className={styles.Items}>
          <img src={cigar} alt="" />
          <p className={styles.Description}>sin sulfatos <br /> ni parabenos</p>
        </div>
        <div className={styles.Items}>
          <img src={correct} alt="" />
          <p className={styles.Description}>dermatológicamente <br /> probado </p>
        </div>
      </div>
    </div>
  );
};

export default Advantage;
