import React from "react";
import { Home } from "./Components/Home/Home";
import { Shell } from "./Components/Shell/Shell";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { getCountry } from "./util/Utils";
import { getUTMs } from "./util/UTM";
import Checkout from "./Components/Checkout/Checkout.js";
function App() {
  getUTMs();
  const country = getCountry();
  console.log(country);

  return (
    <BrowserRouter>
      <Shell>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/comprar" component={Checkout}></Route>
        </Switch>
      </Shell>
    </BrowserRouter>
  );
}

export default App;
