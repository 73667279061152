/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./CardSection.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#223254",
      fontSmoothing: "antialiased",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  },
  
};

function CardSection() {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

export default CardSection;
