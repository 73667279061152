export const getCountry = () => {
  let country = window.location.href;
  country = "https://mymoons.co";
  if (country.includes("mymoons.mx")) {
    document.documentElement.lang = "es-mx";
    return "México";
  } else if (country.includes("mymoons.co")) {
    document.documentElement.lang = "es-co";
    return "Colombia";
  } else return "México";
};
