import React from "react";
import styles from "./LoaderSection.module.css";

export const LoaderSection = props => {
  return (
    <div className={styles.LdsRoller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
