import React from 'react';
import style from '../CheckoutForm/CheckoutForm.module.css';
import styles from './Error.module.css';
import imageFailureDesktop from '../../../assets/images/png/failure_desktop.png';
import imageFailureMobile from '../../../assets/images/png/failure_mobile.png';

export default class Error extends React.Component{


  constructor(props){
    super(props)
    this.handleError = this.props.handleReturn;
    this.data = this.props.data || {};
  }
  handleClick = async e => {
    e.preventDefault();
    let error = false;
    this.data.error = error
    this.handleError(this.data);
  }
  render(){
    window.scrollTo(0,0);
    return (
    <div className={styles.error}>
      <picture>
        <source media="(max-width: 799px)" srcSet={imageFailureMobile}></source>
        <img src={imageFailureDesktop} alt={"checkout_failure"}></img>
      </picture>
      <h1 >¡Oh, no!</h1>
      <h2>Algo salió mal con tu pago. Por favor intenta con otra tarjeta.</h2>
      <div className={styles.buttonContainer}>
        <button type="button" className={`${style.actionButton} ${style.payButton} ${style.payButtonBig}`} onClick={this.handleClick}>Intentar nuevamente</button>
      </div>
    </div>)
  }
}