import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export const Shell = props => {
  return (
    <>
      <Header />
      {props.children}
      <Footer />
    </>
  );
};
