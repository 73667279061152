import React from 'react';
import style from './description.module.css'

const Description = ({ item }) => {

  return (
    <div className={style.ContainerText}>
      <div className={style.CarrouselTitle}>{item.title}</div>
      <div className={style.CarrouselDescription}>{`"${item.description}"`}</div>
    </div>
  );
}

export default Description;